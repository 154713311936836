import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import { HOST } from "../config"

import CHEVRON_RIGHT from "../images/chevron-right.svg"

interface Props {
  title: string
  section: any
  isCategory?: boolean
}

export default (props: Props) => {
  const { title, section, isCategory } = props

  const articles = (section.articles || []).sort(
    (a, b) => a.strapi_id - b.strapi_id
  )

  return (
    <div className={`section digest ${isCategory ? "is-category" : ""}`}>
      <p className="section-title">{title}</p>
      <div className="section-card">
        {articles.map(t => {
          return (
            <Link
              key={t?.strapi_id}
              to={`/article/${t?.strapi_id}`}
              className="section-article"
            >
              <span>{t?.title}</span>
              <img src={CHEVRON_RIGHT} alt={t?.title} />
            </Link>
          )
        })}
      </div>
    </div>
  )
}
